<template>
  <component :is="layout">
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </component>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { computed, onMounted } from 'vue';
import DefaultLayout from '@baseComponents/layout/DefaultLayout.vue';
import { AxiosAdapter, CommonAdapter } from '@adapters';
import { FetchCommonTotalUseCase } from '@useCases';
import { useCommonStore } from '@stores';
const axiosAdapter = new AxiosAdapter();
const commonAdapter = new CommonAdapter(axiosAdapter);
const fetchCommonTotalUseCase = new FetchCommonTotalUseCase(commonAdapter);
const commonStore = useCommonStore();
onMounted(async () => {
  if (!commonStore.isTotalSystemInfo) {
    const data = await fetchCommonTotalUseCase.execute();
    if (data) {
      commonStore.setTotalSystemInfo(data);
    }
  }
});
const route = useRoute();
const layout = computed(() => route.meta.layout || DefaultLayout);
</script>

<style scoped lang="scss">
.index {
  @apply mt-1 font-sans;
}
h1 {
  @apply pd-[31] mg-[10] fs-[22];
}
</style>
