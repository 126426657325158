import { defineStore } from 'pinia';

interface AlertState {
  isAlertVisible: boolean;
  alertMessage: string;
  isConfirm: boolean;
  isVaildate: boolean;
  resolve: ((value: boolean) => void) | null;
}
export const useAlertStore = defineStore('modal', {
  state: (): AlertState => ({
    isAlertVisible: false,
    alertMessage: '',
    isConfirm: false,
    isVaildate: false,
    resolve: null,
  }),
  actions: {
    showAlert(message: string): void {
      this.bodyScroll(true);
      this.alertMessage = message;
      this.isAlertVisible = true;
      this.isVaildate = false;
      this.isConfirm = false;
    },
    showConfirm(message: string): Promise<boolean> {
      return new Promise((resolve) => {
        this.bodyScroll(true);
        this.alertMessage = message;
        this.isAlertVisible = true;
        this.isConfirm = true;
        this.isVaildate = false;
        this.resolve = resolve;
      });
    },
    showValidate(message: string): Promise<boolean> {
      return new Promise((resolve) => {
        this.bodyScroll(true);
        this.alertMessage = message;
        this.isAlertVisible = true;
        this.isVaildate = true;
        this.isConfirm = false;
        this.resolve = resolve;
      });
    },
    confirm(): void {
      if (this.isConfirm || this.isVaildate) {
        this.resolve && this.resolve(true);
      }
      this.bodyScroll(false);
      this.isAlertVisible = false;
    },
    hideAlert(): void {
      if (this.isConfirm || this.isVaildate) {
        this.resolve && this.resolve(false);
      }
      this.bodyScroll(false);
      this.isAlertVisible = false;
    },
    bodyScroll(active: boolean): void {
      active
        ? (document.body.style.overflow = 'hidden')
        : (document.body.style.overflow = 'auto');
    },
  },
});
