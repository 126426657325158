import { ROUTE_NAME } from '@enums';
const facility = {
  path: '/facility',
  redirect: '/facility/clubhouse',
  name: ROUTE_NAME.Facilities,
  children: [
    {
      name: ROUTE_NAME.ClubHouse,
      path: 'clubhouse',
      component: () => import('@views/facility/Clubhouse.vue'),
      meta: {},
    },
    {
      name: ROUTE_NAME.Restaurant,
      path: 'restaurant',
      component: () => import('@views/facility/Restaurant.vue'),
      meta: {},
    },
    {
      name: ROUTE_NAME.LockerRoom,
      path: 'locker-room',
      component: () => import('@views/facility/LockerRoom.vue'),
      meta: {},
    },
  ],
};
export default facility;
