import { ROUTE_NAME } from '@enums';
const courseIntroduction = {
  path: '/course-introduction',
  redirect: '/course-introduction/course-introduction',
  name: ROUTE_NAME.CourseIntroduction,
  children: [
    {
      name: ROUTE_NAME.CourseIntroduction,
      path: 'course-introduction',
      component: () =>
        import('@views/course-introduction/CourseIntroduction.vue'),
      meta: {
        isReady: false,
      },
    },
    {
      name: ROUTE_NAME.EdCourse,
      path: 'ed-course',
      component: () => import('@views/course-introduction/EdCourse.vue'),
      meta: {
        isReady: false,
      },
    },
    {
      name: ROUTE_NAME.SjCourse,
      path: 'sj-course',
      component: () => import('@views/course-introduction/SjCourse.vue'),
      meta: {
        isReady: false,
      },
    },
    {
      name: ROUTE_NAME.HnCourse,
      path: 'hn-course',
      component: () => import('@views/course-introduction/HnCourse.vue'),
      meta: {
        isReady: false,
      },
    },
  ],
};

export default courseIntroduction;
