export enum LUNAR_CODE {
  SOLAR = 'SOLAR',
  LUNAR = 'LUNAR',
}
export enum STATUS_YN {
  YES = 'Y',
  NO = 'N',
}
export enum GENDER_CODE {
  MALE = 'M',
  FEMAIL = 'F',
}
export enum DORMANT_ACCOUNT_CONVERSION_CODE {
  UNTIL_OUT = 'OUT',
  YEAR = '1',
}
export enum ROUTE_NAME {
  Main = 'Main', // 메인
  NotFound = 'NotFound', // 페이지없음
  RepairHomepage = 'RepairHomepage', // 시스템점검
  // auth
  Signin = 'Signin', // 로그인
  SigninAdmin = 'SigninAdmin', // 관리자로그인
  SignupAgree = 'SignupAgree', // 회원가입약관동의
  SignupCertification = 'SignupCertification', // 회원가입인증
  Signup = 'Signup', // 회원가입
  SignupComplete = 'SignupComplete', // 회원가입완료
  UserInfoModify = 'UserInfoModify', // 회원정보수정
  MyInfo = 'MyInfo', //마이페이지
  FindId = 'FindId', // ID 찾기
  FindPassword = 'FindPassword', // PW 찾기
  NewPasswordAccount = 'NewPasswordAccount', // 신규비밀번호생성

  //agree
  ServiceTerms = 'ServiceTerms', // 이용약관
  InternetTerms = 'InternetTerms', // 인터넷이용약관
  PrivacyTerms = 'PrivacyTerms', // 개인정보 처리방침
  PrivacyEmail = 'PrivacyEmail', // 이메일 무단수집거부
  VideoInformation = 'VideoInformation', //영상정보처리기기 운영관리방침

  //클럽소개 club-info
  ClubIntroduction = 'ClubIntroduction', // 클럽소개
  Greeting = 'Greeting', // 인사말
  CountryClubIntroduction = 'CountryClubIntroduction', // 컨트리클럽소개
  Location = 'Location', // 오시는길

  //코스소개 course-info
  CourseInformation = 'CourseInformation', // 코스소개
  CourseIntroduction = 'CourseIntroduction', // 코스소개
  EdCourse = 'EdCourse', // 어등코스
  SjCourse = 'SjCourse', // 송정코스
  HnCourse = 'HnCourse', // 하남코스

  //이용안내 use-information
  UsageInformation = 'UsageInformation', // 이용안내
  FeeInformation = 'FeeInformation', // 요금안내
  ReservationInformation = 'ReservationInformation', // 예약안내
  AgreeTerms = 'AgreeTerms', // 약관동의

  //인터넷예약 internet-resve
  InternetReservation = 'InternetReservation', // 인터넷예약
  Reservation = 'Reservation', // 예약하기
  ReservationStatus = 'ReservationStatus', // 예약확인/취소

  // 부대시설
  Facilities = 'Facilities', // 부대시설
  ClubHouse = 'ClubHouse', // 클럽하우스
  Restaurant = 'Restaurant', // 레스토랑
  LockerRoom = 'LockerRoom', // 로커룸

  // 커뮤니티
  Community = 'Community', // 커뮤니티
  Notice = 'Notice', // 공지사항
  NoticeView = 'NoticeView', // 공지사항 상세
  NoticeWrite = 'NoticeWrite', // 공지사항 작성
  NoticeModify = 'NoticeModify', // 공지사항 작성
  Banner = 'Banner', // 배너
  BannerWrite = 'BannerWrite', // 배너 작성
  BannerModify = 'BannerModify', // 배너 수정
  Archive = 'Archive', // 자료실
  ArchiveView = 'ArchiveView', // 자료실 상세
  ArchiveWrite = 'ArchiveWrite', // 자료실 작성
  ArchiveModify = 'ArchiveModify', // 자료실 작성
  PhotoGallery = 'PhotoGallery', // 포토갤러리

  //정보마당 infoForum-forum
  // Notification = 'Notification', // 클럽소식
  // NotificationView = 'NotificationView', // 클럽소식
  // PhotoGallery = 'PhotoGallery', // 포토갤러리
  // HallOfFame = 'HallOfFame', // 명예의전당
  // WeatherInfo = 'WeatherInfo', // 날씨정보

  //고객라운지 customer-lounge
  CustomerLounge = 'CustomerLounge',

  // VisitStatus = 'VisitStatus', // 내장현황
  // ReservePenaltyStatus = 'ReservePenaltyStatus', // 위약현황
  // ScoreStatus = 'ScoreStatus', // 스코어 현황
  MyInfoModify = 'MyInfoModify', // 회원정보 수정

  ReserveView = 'ReserveView', // 실시간예약
  WaitingReserveView = 'WaitingReserveView', // 대기예약
  ReserveModifyView = 'ReserveModifyView', // 예약변경
  //고객의 소리 voc
  Recommend = 'Recommend', // 고객의 소리
  Withdrawal = 'Withdrawal', // 회원탈퇴
}

export enum RESERVATION_STATUS {
  END = '마감',
  UNOPENED = '오픈 전',
  ING = '오픈',
  PAST = '지난 날짜',
  WAIT = '대기 예약',
  CLOSE = '휴장',
  TODAY = 'TODAY',
}

export enum NO_DATA_SETTINGS_TYPE {
  DEFAULT = 'DEFAULT',
  RESVE = 'RESVE',
}
