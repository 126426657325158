<template>
  <div class="wrapper">
    <header-mobile v-if="commonStore.isMobile" ref="header" class="header" />
    <header-desktop v-if="!commonStore.isMobile" ref="header" class="header" />
    <main>
      <div
        ref="container"
        :class="{
          'menu-size': $route.name !== ROUTE_NAME.Main,
          container:
            $route.name !== ROUTE_NAME.Main &&
            $route.name !== ROUTE_NAME.Greeting,
        }"
      >
        <router-view />
      </div>
    </main>
    <Footer ref="footer" class="footer" />
  </div>
  <MainPopup2 v-if="commonStore.isMobile" />
  <MainPopupDesktop v-if="!commonStore.isMobile" />
  <Alert />
</template>
<script setup lang="ts">
import HeaderMobile from '@baseComponents/HeaderMobile.vue';
import HeaderDesktop from '@baseComponents/HeaderDesktop.vue';
import Footer from '@baseComponents/Footer.vue';
import Alert from '@components/common/Alert.vue';
import { ROUTE_NAME } from '@enums';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useCommonStore } from '@stores';
import MainPopup2 from '@components/common/MainPopup2.vue';
import MainPopupDesktop from '@components/common/MainPopupDesktop.vue';

const header = ref<HTMLElement>();
const container = ref<HTMLElement>();
const footer = ref<HTMLElement>();
const commonStore = useCommonStore();

const adjustContainerHeight = () => {
  if (!container.value) return;
  const headerHeight = header.value?.offsetHeight || 0;
  const footerHeight = footer.value?.offsetHeight || 0;
  container.value.style.minHeight = `calc(100vh - ${headerHeight + footerHeight}px)`;
};

onMounted(() => {
  adjustContainerHeight();
  window.addEventListener('resize', adjustContainerHeight);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', adjustContainerHeight);
});
</script>

<style scoped lang="scss">
.menu-size {
  @apply pt-72;
  @apply desktop:pt-170;
}
main {
  padding-bottom: env(safe-area-inset-bottom);
  @apply bg-white;
}
.wrapper {
  @apply bg-gray-100;
  .container {
    @apply desktop:w-[1200px] desktop:m-auto;
  }
}
</style>
