import { HttpInterface } from '@http';
import { CommonApiEndpoint } from '@api-endpoint';
import {
  FetchAttrListByGroupCodePayload,
  FetchAttrListByGroupCodeResponse,
  FetchBannerListResponse,
  FetchDetailByComCodePayload,
  FetchDetailByComCodeResponse,
  FetchDetailRegionPayload,
  FetchDetailRegionResponse,
  FetchListByGroupCodePayload,
  FetchListByGroupCodeResponse,
  FetchMainBannersResponse,
  FetchMainBoardsPayload,
  FetchMainBoardsResponse,
  FetchSystemInfoResponse,
  RequestCaptchaResponse,
} from '@dto';
import { useCommonStore } from '@stores';

export class CommonAdapter {
  http: HttpInterface;
  commonStore: any;
  constructor(httpInstance: HttpInterface) {
    this.http = httpInstance;
    this.commonStore = useCommonStore();
  }

  async fetchListByGroupCode(
    payload: FetchListByGroupCodePayload
  ): Promise<FetchListByGroupCodeResponse[] | []> {
    try {
      const { groupCode, useFlag = false } = payload;
      const url = this.http.replacedUrl(
        CommonApiEndpoint.fetchListByGroupCode,
        {
          groupCode,
        }
      );
      const response = await this.http.get<FetchListByGroupCodeResponse[]>(
        url,
        {
          useFlag,
        }
      );
      this.commonStore.setAreaCodeList(response.data);
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async fetchDetailRegionList(
    payload: FetchDetailRegionPayload
  ): Promise<FetchDetailRegionResponse[] | []> {
    try {
      const response = await this.http.get<FetchDetailRegionResponse[]>(
        CommonApiEndpoint.fetchDetailRegion,
        payload
      );
      return response.data;
    } catch (error) {
      return [];
    }
  }
  async fetchSystemInfo(): Promise<FetchSystemInfoResponse | null> {
    try {
      const response = await this.http.get<FetchSystemInfoResponse>(
        CommonApiEndpoint.commonTotalInfo
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }
  async fetchDetailByComCode(
    payload: FetchDetailByComCodePayload
  ): Promise<FetchDetailByComCodeResponse | null> {
    try {
      const { groupCode, comCode, useFlag = false } = payload;
      const url = this.http.replacedUrl(
        CommonApiEndpoint.fetchDetailByComCode,
        {
          groupCode,
          comCode,
        }
      );
      const response = await this.http.get<FetchDetailByComCodeResponse>(url, {
        useFlag,
      });
      return response.data;
    } catch (error) {
      return null;
    }
  }
  async fetchAttrListByGroupCode(
    payload: FetchAttrListByGroupCodePayload
  ): Promise<FetchAttrListByGroupCodeResponse[] | []> {
    try {
      const { groupCode, comCode, useFlag = false, idx } = payload;
      const url = this.http.replacedUrl(
        CommonApiEndpoint.fetchAttrListByGroupCode,
        {
          groupCode,
        }
      );
      const response = await this.http.get<FetchAttrListByGroupCodeResponse[]>(
        url,
        {
          useFlag,
          idx,
          comCode,
        }
      );
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async requestCaptcha(): Promise<RequestCaptchaResponse | null> {
    try {
      const response = await this.http.post<RequestCaptchaResponse>(
        CommonApiEndpoint.requestCaptcha
      );
      return response.data;
    } catch (error) {
      return null;
    }
  }

  async fetchMainBoards(
    payload: FetchMainBoardsPayload
  ): Promise<FetchMainBoardsResponse | { newsPosts: [] }> {
    try {
      const response = await this.http.get<FetchMainBoardsResponse>(
        CommonApiEndpoint.fetchMainBoards,
        payload
      );
      // return response.data.newsPosts;
      return {
        newsPosts: response.data.newsPosts,
      };
    } catch (error) {
      return {
        newsPosts: [],
      };
    }
  }
  async fetchMainBanners(
    payload: FetchMainBoardsPayload
  ): Promise<FetchMainBannersResponse | { newsPosts: [] }> {
    try {
      const response = await this.http.get<FetchMainBannersResponse>(
        CommonApiEndpoint.fetchMainBoards,
        payload
      );
      // return response.data.eventPosts;
      return {
        newsPosts: response.data.newsPosts,
      };
    } catch (error) {
      return {
        newsPosts: [],
      };
    }
  }
  async fetchBanners(): Promise<FetchBannerListResponse> {
    try {
      const response = await this.http.get<FetchBannerListResponse>(
        CommonApiEndpoint.fetchBanners
      );
      return response.data;
    } catch (error) {
      return {
        total: 0,
        twMainBanners: [],
      };
    }
  }
}
