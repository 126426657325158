import { ROUTE_NAME } from '@enums';

const community = {
  path: '/community',
  redirect: '/community/notice',
  name: ROUTE_NAME.Community,
  children: [
    {
      name: ROUTE_NAME.Notice,
      path: 'notice',
      component: () => import('@views/community/Notice.vue'),
    },
    {
      name: ROUTE_NAME.NoticeWrite,
      path: 'notice/write',
      component: () => import('@views/community/NoticeWrite.vue'),
    },
    {
      name: ROUTE_NAME.NoticeModify,
      path: 'notice/modify/:postId',
      component: () => import('@views/community/NoticeModify.vue'),
    },
    {
      name: ROUTE_NAME.NoticeView,
      path: 'notice/:postId',
      component: () => import('@views/community/NoticeView.vue'),
    },
    {
      name: ROUTE_NAME.Banner,
      path: 'banner',
      component: () => import('@views/community/Banner.vue'),
    },
    {
      name: ROUTE_NAME.BannerWrite,
      path: 'banner/write',
      component: () => import('@views/community/BannerWrite.vue'),
    },
    {
      name: ROUTE_NAME.BannerModify,
      path: 'banner/modify/:postId',
      component: () => import('@views/community/BannerModify.vue'),
    },
    {
      name: ROUTE_NAME.Archive,
      path: 'archive',
      component: () => import('@views/community/Archive.vue'),
    },
    {
      name: ROUTE_NAME.ArchiveView,
      path: 'archive/:postId',
      component: () => import('@views/community/ArchiveView.vue'),
    },
    {
      name: ROUTE_NAME.ArchiveWrite,
      path: 'archive/write',
      component: () => import('@views/community/ArchiveWrite.vue'),
    },
    {
      name: ROUTE_NAME.ArchiveModify,
      path: 'archive/modify/:postId',
      component: () => import('@views/community/ArchiveModify.vue'),
    },
    {
      name: ROUTE_NAME.PhotoGallery,
      path: 'photo-gallery',
      component: () => import('@views/community/PhotoGallery.vue'),
    },
  ],
};
export default community;
