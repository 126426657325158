export const CommonApiEndpoint = {
  fetchListByGroupCode: '/system/common-group-codes/:groupCode/common-codes',
  fetchDetailByComCode:
    '/system/common-group-codes/:groupCode/common-codes/:comCode',
  fetchAttrListByGroupCode:
    '/system/common-group-codes/:groupCode/common-attrbs',
  // fetchPenaltyConfigList: '/system/configurations/penalty', // 페널티 구성 조회??
  // fetchGroupRegistSetting: '/system/configurations/group-receipt', // 단체접수환경설정 조회?
  fetchDetailRegion: '/sis', // 시군구 조회
  requestCaptcha: '/captcha',
  fetchMainBoards: '/main/boards',
  fetchBanners: '/banners',
  commonTotalInfo: '/system/configurations',
};
