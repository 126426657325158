import { ROUTE_NAME } from '@enums';
const clubIntroduction = {
  path: '/club-introduction',
  redirect: '/club-info/club-introduction',
  name: ROUTE_NAME.ClubIntroduction,
  children: [
    {
      name: ROUTE_NAME.Greeting,
      path: 'greeting',
      component: () => import('@views/club-introduction/Greeting.vue'),
      meta: {
        isReady: false,
      },
    },
    {
      name: ROUTE_NAME.CountryClubIntroduction,
      path: 'contry-club',
      component: () =>
        import('@views/club-introduction/CountryClubIntroduction.vue'),
      meta: {},
    },
    {
      name: ROUTE_NAME.Location,
      path: 'location',
      component: () => import('@views/club-introduction/Location.vue'),
      meta: {
        isReady: false,
      },
    },
  ],
};

export default clubIntroduction;
