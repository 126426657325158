import { BannerPort } from '@ports';
import { BannerApiEndpoint } from '@api-endpoint';
import { HttpInterface } from '@http';
import {
  FetchBannerListResponse,
  FetchBannerResponse,
  RegistBannerPayload,
  RegistBannerResponse,
  UpdateBannerPayload,
  UpdateBannerResponse,
} from '@dto';

export class BannerAdapter implements BannerPort<any, any> {
  http: HttpInterface;
  constructor(httpInstance: HttpInterface) {
    this.http = httpInstance;
  }
  async fetchBannerList(): Promise<FetchBannerListResponse | any> {
    try {
      const response = await this.http.get<FetchBannerListResponse>(
        BannerApiEndpoint.fetchBannerList
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  }
  async fetchBanner(bannerId: number): Promise<FetchBannerResponse> {
    const replacedUrl = this.http.replacedUrl(BannerApiEndpoint.fetchBanner, {
      bannerId,
    });
    const response = await this.http.get<FetchBannerResponse>(replacedUrl);
    return response.data;
  }
  async registBanner(
    payload: RegistBannerPayload
  ): Promise<RegistBannerResponse> {
    const response = await this.http.post<RegistBannerResponse>(
      BannerApiEndpoint.registBanner,
      payload
    );
    return response.data;
  }
  async updateBanner(
    payload: UpdateBannerPayload
  ): Promise<UpdateBannerResponse> {
    const replacedUrl = this.http.replacedUrl(BannerApiEndpoint.updateBanner, {
      bannerId: payload.bannerId,
    });
    const response = await this.http.put<UpdateBannerResponse>(
      replacedUrl,
      payload
    );
    return response.data;
  }
  async deleteBanner(bannerId: number): Promise<void> {
    const replacedUrl = this.http.replacedUrl(BannerApiEndpoint.deleteBanner, {
      bannerId,
    });
    await this.http.delete(replacedUrl);
  }
}
