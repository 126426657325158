export const AuthApiEndpoint = {
  signIn: '/auth/issue',
  signInAdmin: '/auth/issue-admin',
  signOut: '/auth/withdraw',
  checkIdDuplicate: '/users/check-id', // 아이디 중복확인
  findId: '/users/find-id', // 아이디 찾기
  requestPasswordVerificationCode: '/users/identification', // 신규비밀번호 생성 인증 시작
  checkPasswordVerificationCode: '/users/verify-number', // 신규비밀번호 생성 인증 확인
  updatePassword: '/users/password', // 토큰 인증 후 비밀번호 변경(신규비밀번호 생성)
};
