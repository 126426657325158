<template>
  <div class="backdrop" v-if="isShow && isMain">
    <div class="popup-container">
      <div class="button-wrapper">
        <button
          class="close-button"
          aria-label="Close"
          @click="handleClosePopup"
        >
          팝업닫기
          <img src="@assets/img/icon-close-main-popup.svg" alt="팝업닫기" />
        </button>
      </div>

      <div class="mobile">
        <swiper
          :slides-per-view="1"
          :loop="true"
          :navigation="{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }"
          :autoplay="{ delay: 3000, disableOnInteraction: false }"
          :modules="[Pagination, Autoplay, Navigation]"
          :speed="1000"
          :pagination="{
            el: '.custom-pagination',
            clickable: true,
            renderBullet: renderBullet,
          }"
          @swiper="onSwiper"
        >
          <swiper-slide
            v-for="banner in banners?.twMainBanners"
            :key="banner.id"
            @click="handleMoveNoticeDetail(banner)"
          >
            <img :src="banner.twImage.url" alt="메인배너" />
          </swiper-slide>
        </swiper>
        <!--        <div class="swiper-button-prev">left</div>-->
        <!--        <div class="swiper-button-next">right</div>-->
        <div class="custom-pagination"></div>
      </div>
      <div class="desktop"></div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { RouteLocationNormalized, useRouter } from 'vue-router';
import { ROUTE_NAME } from '@enums';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { FetchBannerListResponse, TwMainBanner } from '@dto';
import { AxiosAdapter, BannerAdapter } from '@adapters';
import { FetchBannerListUsecase } from '@useCases';
import { Swiper as SwiperClass } from 'swiper/types';

const isShow = ref(true);
const isMain = ref(false);
const router = useRouter();
const swiperInstance = ref<SwiperClass | null>(null);
const banners = ref<FetchBannerListResponse>({
  total: 0,
  twMainBanners: [],
});

const onSwiper = (swiper: SwiperClass) => {
  swiperInstance.value = swiper;
};
const handleClosePopup = () => {
  isShow.value = false;
};
// const handleSwiperPrev = () => {
//   swiperInstance.value?.slidePrev();
// };
// const handleSwiperNext = () => {
//   swiperInstance.value?.slideNext();
// };

const handleMoveNoticeDetail = (banner: TwMainBanner) => {
  const { link, postId, boardId } = banner;
  // link가 있으면 새탭으로 링크이동, 아닐 경우 내부 이동
  if (link) {
    window.open(link);
    return;
  }
  if (!postId || !boardId) return;
  router.push({
    name: boardId === 'news' ? ROUTE_NAME.NoticeView : ROUTE_NAME.ArchiveView,
    params: { postId },
  });
};

const renderBullet = (_: any, className: string) => {
  return `<span class="${className} custom-bullet"></span>`;
};

onMounted(async () => {
  isMain.value = router.currentRoute.value.name === ROUTE_NAME.Main;
  // checkDoNotShow();
  const axiosAdapter = new AxiosAdapter();
  const bannerAdapter = new BannerAdapter(axiosAdapter);
  const fetchBannerListUsecase = new FetchBannerListUsecase(bannerAdapter);
  banners.value = await fetchBannerListUsecase.execute();
});

watch(router.currentRoute, (value: RouteLocationNormalized) => {
  isMain.value = value.name === ROUTE_NAME.Main;
});
</script>

<style scoped lang="scss">
.backdrop {
  @apply flex fixed bg-black bg-opacity-80 w-screen h-screen left-0 top-0 justify-center items-center;
  z-index: 9999;
}
.button-wrapper {
  @apply flex justify-end w-full text-white fs-[14] mb-10;
  .close-button {
    @apply flex;
  }
}
.popup-container {
  @apply w-3/5;
}
.mobile {
  aspect-ratio: 1 / 1;
  img {
    @apply w-full object-cover;
  }
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  background: none;
  z-index: 10;
  cursor: pointer;
}

.swiper-button-prev {
  left: 20px;
}

.swiper-button-next {
  right: 20px;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
<style lang="scss">
.custom-pagination {
  @apply flex justify-center items-center mt-10;
  .custom-bullet {
    @apply bg-white;
  }
}
</style>
