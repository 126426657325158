import { createApp } from 'vue';
import 'normalize.css';
import '@assets/css/index.css';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';
import App from './App.vue';
import router from '@infra/router';
import VueGtag from 'vue-gtag';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';

const app = createApp(App);
const pinia = createPinia();

if (import.meta.env.VITE_APP_NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracingOptions: {
      trackComponents: true,
      hooks: ['activate', 'mount', 'update', 'unmount'],
    },
  });
}

window.addEventListener('vite:preloadError', (event: Event) => {
  event.preventDefault();
  window.location.reload();
});

if (import.meta.env.VITE_APP_NODE_ENV === 'production') {
  app.use(VueGtag, {
    config: {
      id: import.meta.env.VITE_APP_GA,
      params: {
        anonymize_ip: true,
      },
    },
  });
}

app.use(router);
app.use(pinia);
app.mount('#app');
