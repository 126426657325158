<template>
  <header
    :class="{
      active: isScrolled || !isMain || isMobileMenuClicked,
      open: isMobileMenuClicked,
    }"
  >
    <div class="top-bar">
      <router-link
        to="/"
        class="logo"
        @click="
          () => {
            if ($route.name === ROUTE_NAME.Main) {
              isMobileMenuClicked = false;
              alertStore.bodyScroll(false);
            }
          }
        "
      >
        <img
          :src="`/logo-${isScrolled || !isMain || isMobileMenuClicked ? 'color' : 'white'}.svg`"
          alt="메인로고"
        />
      </router-link>
      <div class="mobile-menu-wrapper">
        <button class="mobile-menu-icon" @click="handleToggleMobileMenu">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
      <ul class="auth-menu">
        <li v-if="!commonStore.isSignIn">
          <router-link :to="{ name: ROUTE_NAME.Signin }">로그인</router-link>
        </li>
        <li v-if="commonStore.isSignIn" @click="handleSignout">로그아웃</li>
        <li class="signup">
          <router-link
            v-if="!commonStore.isSignIn"
            :to="{ name: ROUTE_NAME.SignupAgree }"
            >회원가입</router-link
          >
          <router-link
            v-if="commonStore.isSignIn"
            :to="{ name: ROUTE_NAME.UserInfoModify }"
            >마이페이지</router-link
          >
        </li>
      </ul>
    </div>
    <!--  모바일 gnb : start -->
    <div class="gnb-mobile">
      <ul class="quick-menu">
        <li>
          <router-link :to="{ name: ROUTE_NAME.Reservation }"
            >예약하기</router-link
          >
        </li>
        <li>
          <router-link
            v-if="commonStore.isSignIn"
            :to="{ name: ROUTE_NAME.UserInfoModify }"
            >마이페이지</router-link
          >
          <router-link
            v-if="!commonStore.isSignIn"
            :to="{ name: ROUTE_NAME.SignupAgree }"
            >회원가입</router-link
          >
        </li>
      </ul>
      <ul class="gnb">
        <li v-for="(gnb, index) in gnbList2" :key="index">
          <div
            class="gnb-item-wrapper"
            :class="{ active: index === activeLnb }"
            @click="handleToggleLnb(index)"
          >
            <span class="title">
              {{ gnb.title }}
            </span>
            <img
              class="drop-down"
              src="@assets/img/icon-gnb-dropdown.svg"
              alt="드롭다운 아이콘"
            />
          </div>
          <ul v-if="index === activeLnb" class="lnb">
            <li
              v-for="(item, index) in gnb.children"
              :key="index"
              @click="handleRoute(item)"
            >
              {{ item.title }}
              <!--            <router-link :to="{ name: item.name }" class="lnb-title">{{-->
              <!--              item.title-->
              <!--            }}</router-link>-->
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <!--  모바일 gnb : end -->
  </header>
</template>
<script setup lang="ts">
import { ROUTE_NAME } from '@enums';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useRouter, RouteLocationNormalized } from 'vue-router';
import { useCommonStore, useUserStore } from '@stores';
import { AuthAdapter, AxiosAdapter } from '@adapters';
import { SignOutUseCase } from '@useCases';
import { useAlertStore } from '@stores';

interface LnbItem {
  title: string;
  name: string;
  isAdmin?: boolean;
}
interface GnbItem {
  title: string;
  name: string;
  children: LnbItem[];
}

const alertStore = useAlertStore();
const userStore = useUserStore();
const axiosAdapter = new AxiosAdapter();
const authAdapter = new AuthAdapter(axiosAdapter);
const commonStore = useCommonStore();
const { currentRoute, push } = useRouter();
const isScrolled = ref<boolean>(false);
const isMain = ref<boolean>(false);
const isMobileMenuClicked = ref<boolean>(false);
const lnbList = ref<LnbItem[][]>([
  [
    {
      title: '인사말',
      name: ROUTE_NAME.Greeting,
    },
    {
      title: '컨트리클럽소개',
      name: ROUTE_NAME.CountryClubIntroduction,
    },
    {
      title: '오시는길',
      name: ROUTE_NAME.Location,
    },
  ],
  [
    {
      title: '코스소개',
      name: ROUTE_NAME.CourseIntroduction,
    },
    {
      title: '어등코스',
      name: ROUTE_NAME.EdCourse,
    },
    {
      title: '송정코스',
      name: ROUTE_NAME.SjCourse,
    },
    {
      title: '하남코스',
      name: ROUTE_NAME.HnCourse,
    },
  ],
  [
    {
      title: '이용안내',
      name: ROUTE_NAME.UsageInformation,
    },
    {
      title: '요금안내',
      name: ROUTE_NAME.FeeInformation,
    },
    {
      title: '예약안내',
      name: ROUTE_NAME.ReservationInformation,
    },
    {
      title: '이용약관',
      name: ROUTE_NAME.AgreeTerms,
    },
  ],
  [
    {
      title: '인터넷예약',
      name: ROUTE_NAME.Reservation,
    },
    {
      title: '예약확인 및 취소',
      name: ROUTE_NAME.ReservationStatus,
    },
  ],
  [
    {
      title: '클럽하우스',
      name: ROUTE_NAME.ClubHouse,
    },
    {
      title: '레스토랑',
      name: ROUTE_NAME.Restaurant,
    },
    {
      title: '락카룸',
      name: ROUTE_NAME.LockerRoom,
    },
  ],
  [
    {
      title: '공지사항',
      name: ROUTE_NAME.Notice,
    },
    {
      title: '배너관리',
      name: ROUTE_NAME.Banner,
      isAdmin: true,
    },
    {
      title: '자료실',
      name: ROUTE_NAME.Archive,
    },
    {
      title: '포토갤러리',
      name: ROUTE_NAME.PhotoGallery,
    },
  ],
]);
const lnbList2 = ref<LnbItem[][]>([]);
const gnbList2 = ref<GnbItem[]>();
const gnbList = [
  {
    title: '클럽소개',
    name: ROUTE_NAME.ClubIntroduction,
  },
  {
    title: '코스안내',
    name: ROUTE_NAME.CourseInformation,
  },
  {
    title: '이용안내',
    name: ROUTE_NAME.UsageInformation,
  },
  {
    title: '인터넷예약',
    name: ROUTE_NAME.InternetReservation,
  },
  {
    title: '부대시설',
    name: ROUTE_NAME.Facilities,
  },
  {
    title: '커뮤니티',
    name: ROUTE_NAME.Community,
  },
];

const activeLnb = ref<number>(-1);

const handleToggleMobileMenu = () => {
  isMobileMenuClicked.value = !isMobileMenuClicked.value;
  alertStore.bodyScroll(isMobileMenuClicked.value);
};

const handleToggleLnb = (index: number) => {
  if (activeLnb.value === index) {
    activeLnb.value = -1;
  } else {
    activeLnb.value = index;
  }
};

const handleScroll = () => {
  scrollY > 200 ? (isScrolled.value = true) : (isScrolled.value = false);
};

const handleRoute = (item: { title: string; name: string }) => {
  // activeLnb.value = -1;
  alertStore.bodyScroll(false);
  isMobileMenuClicked.value = false;
  currentRoute.value.name !== item.name && push({ name: item.name });
};

const handleSignout = async () => {
  const signOutUseCase = new SignOutUseCase(authAdapter);
  await signOutUseCase.execute();
  await push({ name: ROUTE_NAME.Main });
};

watch(currentRoute, (value: RouteLocationNormalized) => {
  alertStore.bodyScroll(false);
  isMain.value = false;
  isMobileMenuClicked.value = false;
  isScrolled.value = false;

  value.name === ROUTE_NAME.Main
    ? (isMain.value = true)
    : (isMain.value = false);
});

watch(
  () => userStore.grant,
  (userGrant) => {
    lnbList2.value = lnbList.value.map((group) =>
      group.filter((item) => !item.isAdmin || userGrant === 'WEB_ADMIN')
    );
    gnbList2.value = gnbList.map((gnb, index) => ({
      ...gnb,
      children: lnbList2.value[index],
    }));
  },
  {
    immediate: true,
  }
);

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style scoped lang="scss">
header {
  @apply w-full fixed z-50;
  //@apply desktop:wi-[800] desktop:left-1/2 desktop:transform desktop:-translate-x-1/2;
  transition: all 0.3s ease;
  .top-bar {
    @apply flex justify-between items-center px-16 py-28 fixed w-full;
    @apply desktop:justify-end;
    //@apply desktop:px-20 desktop:wi-[800];
    //background-image: linear-gradient(
    //  to bottom,
    //  rgba(0, 0, 0, 0.2) 30%,
    //  rgba(0, 0, 0, 0)
    //);
    .logo {
      @apply wi-[155] he-[24] absolute left-1/2 transform -translate-x-1/2;
      aspect-ratio: 155 / 24;
      img {
        @apply w-full h-full;
      }
    }
    .auth-menu {
      @apply flex text-white fs-[14] items-center cursor-pointer;
      @apply desktop:gap-10 desktop:justify-end;
      li {
        a {
          @apply h-full block leading-4;
        }
      }
      .signup {
        @apply hidden;
        @apply desktop:block;
      }
    }
    // 모바일 메뉴 css
    .mobile-menu-wrapper {
      @apply desktop:hidden;
    }
    .mobile-menu-icon {
      @apply wi-[20] flex flex-col relative justify-around gap-6;
      span {
        @apply bg-white block w-full br-[1];
        height: 1px;
        transition:
          transform 0.3s ease,
          opacity 0.2s ease;
        &:nth-child(2) {
          @apply w-2/3;
        }
      }
      &.open {
        span {
          @apply bg-black;
          &:nth-child(1) {
            transform: translateY(7px) rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: translateY(-7px) rotate(-45deg);
          }
        }
      }
    }
  }
  .gnb-mobile {
    @apply desktop:hidden;
  }
  .quick-menu {
    @apply hidden;
  }
  .gnb {
    @apply hidden;
  }
  &.active {
    @apply bg-white;
    box-shadow: 0 0.5px 10px 0 rgba(0, 0, 0, 0.15);
    .top-bar {
      @apply bg-white;
      box-shadow: 0 0.5px 10px 0 rgba(0, 0, 0, 0.15);
      .auth-menu {
        @apply text-black;
      }
    }
    .mobile-menu-icon {
      span {
        @apply bg-black;
      }
    }
  }
  &.open {
    @apply bg-white h-screen overflow-y-auto;
    .top-bar {
      @apply bg-white;
    }
    .mobile-menu-icon {
      span {
        @apply bg-black;
        &:nth-child(1) {
          transform: translateY(7px) rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: translateY(-7px) rotate(-45deg);
        }
      }
    }
    .quick-menu {
      @apply flex bg-gray-100 text-white py-16 fs-[16] font-medium mt-73;
      li {
        @apply basis-1/2 text-center;
      }
    }
    .gnb {
      @apply block cursor-pointer pb-[100px];
      .gnb-item-wrapper {
        @apply flex justify-between items-center px-28 py-24 border-b border-gray-400;
        .title {
          @apply fs-[20] font-medium;
        }
        .drop-down {
          @apply wi-[14] he-[6];
        }
        &.active {
          @apply border-orange-100 border-b-2;
          .drop-down {
            animation: rotate 0.5s ease forwards;
          }
        }
      }
    }
    .lnb {
      @apply bg-gray-1000;
      li {
        @apply px-24 py-14 fs-[18] cursor-pointer;
      }
    }
  }
}
@keyframes showLnb {
  from {
    opacity: 0;
    transform: translateY(-150px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hideLnb {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-150px);
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
</style>
