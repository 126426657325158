import { ROUTE_NAME } from '@enums';
const useInformation = {
  path: '/use-information',
  redirect: '/use-information/usage-information',
  name: ROUTE_NAME.UsageInformation,
  children: [
    {
      name: ROUTE_NAME.UsageInformation,
      path: 'usage-information',
      component: () => import('@views/use-information/UsageInformation.vue'),
      meta: {},
    },
    {
      name: ROUTE_NAME.FeeInformation,
      path: 'fee-information',
      component: () => import('@views/use-information/FeeInformation.vue'),
      meta: {
        isReady: false,
      },
    },
    {
      name: ROUTE_NAME.ReservationInformation,
      path: 'reservation-information',
      component: () =>
        import('@views/use-information/ReservationInformation.vue'),
      meta: {
        isReady: false,
      },
    },
    {
      name: ROUTE_NAME.AgreeTerms,
      path: 'agree-terms',
      component: () => import('@views/use-information/AgreeTerms.vue'),
      meta: {},
    },
  ],
};
export default useInformation;
