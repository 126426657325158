<template>
  <div class="backdrop" :class="{ active: isAlertVisible }">
    <div class="alert-container">
      <p class="content" v-html="alertMessage"></p>
      <div class="button-wrapper">
        <button
          @click="store.hideAlert"
          class="close"
          :class="{ 'is-confirm': store.isConfirm }"
        >
          {{ store.isConfirm ? '취소' : '닫기' }}
        </button>
        <button v-if="store.isConfirm" class="confirm" @click="store.confirm">
          확인
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAlertStore } from '@stores';
import { storeToRefs } from 'pinia';
const store = useAlertStore();
const { isAlertVisible, alertMessage } = storeToRefs(store);
</script>
<style scoped lang="scss">
.backdrop {
  @apply flex fixed items-center justify-center bg-black bg-opacity-40 w-screen h-screen left-0 top-0 z-50;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
  &.active {
    opacity: 1;
    visibility: visible;
  }
}
.alert-container {
  @apply bg-white wi-[400] br-[4] overflow-hidden;
  .content {
    @apply fs-[16] px-25 py-29 fw-[300] text-center text-gray-100;
    white-space: pre-line;
    letter-spacing: -0.45px;
  }
  .button-wrapper {
    @apply flex;
  }
  button {
    @apply block w-full py-14 fs-[16] font-bold text-center text-white;
    letter-spacing: -0.9px;
    &.close {
      @apply bg-gray-300;
      &.is-confirm {
        @apply bg-gray-300;
      }
    }
    &.confirm {
      @apply bg-blue-200;
    }
  }
}
@media (max-width: 884px) {
  .alert-container {
    width: 80%;
    .content {
      @apply fs-[16] fw-[500];
    }
    button {
      @apply fs-[16];
    }
  }
}
</style>
