import { RouteRecordRaw } from 'vue-router';
import { ROUTE_NAME } from '@enums';
import clubIntroduction from '@infra/router/module/club-introduction';
import courseIntroduction from '@infra/router/module/course-introduction';
import useInformation from '@infra/router/module/use-information';
import reservation from '@infra/router/module/reservation';
import facility from '@infra/router/module/facility';
import community from '@infra/router/module/community';

const routes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAME.Main,
    path: '/',
    component: () => import('@views/Index.vue'),
    meta: {
      hasSectionTitle: false,
    },
  },
  {
    name: ROUTE_NAME.RepairHomepage,
    path: '/repairs',
    component: () => import('@views/RepairHomepage.vue'),
    meta: {
      layout: 'ClearLayout',
    },
  },
  {
    name: ROUTE_NAME.NotFound,
    path: '/:pathMatch(.*)*',
    component: () => import('@views/NotFound.vue'),
    meta: {
      layout: 'ClearLayout',
      hasSectionTitle: false,
    },
  },
  {
    name: ROUTE_NAME.Signin,
    path: '/signin',
    component: () => import('@views/auth/Signin.vue'),
    meta: {},
  },
  {
    name: ROUTE_NAME.SigninAdmin,
    path: '/signin-admin',
    component: () => import('@views/auth/SigninAdmin.vue'),
    meta: {},
  },
  {
    name: ROUTE_NAME.SignupAgree,
    path: '/signup-agree',
    component: () => import('@views/auth/SignupAgree.vue'),
    meta: {},
  },
  {
    name: ROUTE_NAME.SignupCertification,
    path: '/signup-certification',
    component: () => import('@views/auth/SignupCertification.vue'),
    meta: {},
  },
  {
    name: ROUTE_NAME.Signup,
    path: '/signup',
    component: () => import('@views/auth/Signup.vue'),
    meta: {},
  },
  {
    name: ROUTE_NAME.SignupComplete,
    path: '/signup-complete',
    component: () => import('@views/auth/SignupComplete.vue'),
    meta: {},
  },
  {
    name: ROUTE_NAME.UserInfoModify,
    path: '/user-info-modify',
    component: () => import('@views/auth/UserInfoModify.vue'),
    meta: {},
  },
  {
    name: ROUTE_NAME.Withdrawal,
    path: '/withdrawal',
    component: () => import('@views/auth/Withdrawal.vue'),
    meta: {},
  },
  {
    name: ROUTE_NAME.FindId,
    path: '/find-id',
    component: () => import('@views/auth/FindId.vue'),
    meta: {},
  },
  {
    name: ROUTE_NAME.FindPassword,
    path: '/find-password',
    component: () => import('@views/auth/FindPassword.vue'),
    meta: {},
  },
  {
    name: ROUTE_NAME.NewPasswordAccount,
    path: '/new-password-account',
    component: () => import('@views/auth/NewPasswordAccount.vue'),
    meta: {},
  },
  {
    name: ROUTE_NAME.VideoInformation,
    path: '/video-information',
    component: () => import('@views/agree/VideoInformation.vue'),
    meta: {},
  },
  {
    name: ROUTE_NAME.PrivacyTerms,
    path: '/privacy-terms',
    component: () => import('@views/agree/PrivacyTerms.vue'),
    meta: {},
  },
  clubIntroduction,
  courseIntroduction,
  useInformation,
  reservation,
  facility,
  community,
];

export default routes;
