<template>
  <footer>
    <section class="terms">
      <ul>
        <li>
          <router-link :to="{ name: ROUTE_NAME.AgreeTerms }"
            >이용약관</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: ROUTE_NAME.PrivacyTerms }">
            개인정보처리방침
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: ROUTE_NAME.VideoInformation }"
            >영상정보처리기기운영 &middot; 관리방침
          </router-link>
        </li>
      </ul>
    </section>
    <div class="text-wrapper">
      <img class="footer-logo" src="/logo-footer.svg" alt="푸터 로고" />
      <section class="company-info">
        <h3>어등산 컨트리클럽</h3>
        <address>
          광주광역시 광산구 무진대로 31(운수동)&nbsp;
          <ul>
            <li>대표전화 : 062)605-3000</li>
            <li>팩스 : 062)605-3009</li>
            <li>사업자등록번호: 408-81-77911</li>
          </ul>
        </address>
        <p class="copy-right">
          Copyright &copy; 2012 EODEUNGSAN Country Club. All Right Reserved.
        </p>
      </section>
    </div>
    <button type="button" class="go-to-top" @click="handleScrollTop">
      <img src="/icon-to-top.svg" alt="위로 가기" />
    </button>
  </footer>
</template>
<script setup lang="ts">
import { ROUTE_NAME } from '@enums';

const handleScrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};
</script>

<style scoped lang="scss">
footer {
  @apply bg-gray-200 px-16 pb-92 pt-23 flex flex-col gap-20 relative;
}
.text-wrapper {
  @apply desktop:flex desktop:gap-x-50;
}
.footer-logo {
  @apply wi-[181] he-[28] mb-20;
}
.company-info {
  @apply text-gray-300 fs-[16] fw-[400];
  h3 {
    @apply hidden fs-[20] font-bold text-white mb-18 -mt-4;
    @apply desktop:block;
  }
  address {
    @apply desktop:flex desktop:fs-[16] desktop:text-gray-300;
    font-style: normal;
  }
}
.terms {
  @apply text-white fs-[16] fw-[500];
  ul {
    @apply flex flex-wrap gap-x-20 gap-y-6;
    @apply desktop:justify-end;
  }
  li {
    @apply relative;

    &:after {
      @apply absolute top-4 bg-white;
      content: '';
      width: 1px;
      height: 12px;
    }
    &:after {
      right: -10px;
    }
    &:last-child {
      &:after {
        @apply hidden;
      }
    }
  }
}
.company-info {
  @apply fs-[16] text-gray-300;
  ul {
    @apply flex flex-wrap gap-x-20;
    li {
      @apply relative;
      &:after {
        @apply absolute top-4 bg-gray-300;
        content: '';
        width: 1px;
        height: 12px;
      }
      &:after {
        right: -10px;
      }
      &:last-child {
        &:after {
          @apply hidden;
        }
      }
    }
  }
}
.copy-right {
  @apply desktop:mt-4;
}
.go-to-top {
  @apply wi-[50] he-[50] bg-gray-600 absolute bottom-0 right-0 flex justify-center items-center cursor-pointer;
}
</style>
