import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { HttpInterface } from '@http';
import {
  useAlertStore,
  useCommonStore,
  useSettingStore,
  useUserStore,
} from '@stores';
import { useRouter } from 'vue-router';
import { ROUTE_NAME } from '@enums';
import { ERROR_MESSAGES } from '@constants';
import * as Sentry from '@sentry/vue';

interface ExtendAxiosInstance {
  delete<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig<D>
  ): Promise<R>;
}
type CustomAxiosInstance = AxiosInstance & ExtendAxiosInstance;
export class AxiosAdapter implements HttpInterface {
  private readonly axiosInstance: CustomAxiosInstance;
  private alertStore = useAlertStore();
  private commonStore = useCommonStore();
  private userStore = useUserStore();
  private router = useRouter();
  private settingStore = useSettingStore();

  constructor(config?: AxiosRequestConfig) {
    // create axios instance
    this.axiosInstance = axios.create({
      baseURL: import.meta.env.VITE_API_BASE_URL || 'http://localhost:3000',
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 10000, // 10초
      ...config,
    }) as CustomAxiosInstance;
    // setting interceptors
    const handle400Error = async (errorMessage: string, errorCode: string) => {
      // const alertStore = useAlertStore();
      console.log(errorCode);
      if (errorCode === 'NO_PASSWORD') {
        await this.alertStore.showConfirm(errorMessage);
        await this.router.push({ name: ROUTE_NAME.NewPasswordAccount });
      } else {
        await this.alertStore.showValidate(errorMessage);
      }
      // #3 토스트
      // toast.error('로그인이 필요합니다.');
      // 로그인 페이지로 리디렉트 등의 처리
    };
    const handle401Error = async (errorMessage: string) => {
      console.error('401 Unauthorized Error');
      await this.alertStore.showValidate(errorMessage);
      this.commonStore.clearAccessToken();
      await this.router.push({ name: ROUTE_NAME.Signin });
      // toast.error('로그인이 필요합니다.');
      // 로그인 페이지로 리디렉트 등의 처리
    };

    const handle403Error = async (errorMessage: string) => {
      console.error('403 Forbidden Error');
      await this.alertStore.showValidate(errorMessage);
      // toast.error('권한이 없습니다.');
      // 적절한 메시지 표시 또는 다른 페이지로 리디렉트
    };

    const handle404Error = async (errorMessage: string) => {
      console.error('404 Not Found Error');
      await this.alertStore.showValidate(errorMessage);
      // toast.error('페이지를 찾을 수 없습니다.');
      // "페이지를 찾을 수 없습니다" 메시지 표시
    };

    const handle500Error = async (errorMessage: string) => {
      console.error('500 Internal Server Error');
      await this.alertStore.showValidate(errorMessage);
      // toast.error('서버 오류가 발생했습니다.');
      // "서버 오류가 발생했습니다" 메시지 표시
    };
    const handle503Error = async (error: AxiosResponse) => {
      const data = error.data;
      if (!Object.keys(data).length || typeof data === 'string') {
        await this.router.push('/repairs');
      } else {
        const { description, excuse, title, start, end } = data;
        const query: {
          [key: string]: string;
        } = {};
        if (description) {
          query['description'] = description;
        }
        if (excuse) {
          query['excuse'] = excuse;
        }
        if (title) {
          query['title'] = title;
        }
        if (start) {
          query['start'] = start;
        }
        if (end) {
          query['end'] = end;
        }
        await this.router.push({
          path: '/repairs',
          query,
        });
      }
    };
    const handleErrorResponse = async (
      error: AxiosError<{
        data:
          | string
          | {
              description: string;
              excuse: string;
              title: string;
              start: string;
              end: string;
            };
        status: string;
      }>
    ) => {
      if (axios.isAxiosError(error) && error.response) {
        // 서버에서 응답을 받았으나 오류가 발생한 경우
        const errorStatus = error.response.status;
        const errorCode = error.response.data.status;
        const errorData = error.response.data.data;
        let errorMessage =
          ERROR_MESSAGES[errorCode as keyof typeof ERROR_MESSAGES];
        if (errorData && errorMessage.match(':v')?.length) {
          if (typeof errorData === 'string') {
            errorMessage = errorMessage.replace(':v', errorData);
          }
        }
        if (error.config?.url?.indexOf('/weathers') !== -1) {
          return;
        }

        switch (errorStatus) {
          case 400:
            await handle400Error(errorMessage, errorCode);
            break;
          case 401:
            await handle401Error(errorMessage);
            break;
          case 403:
            await handle403Error(errorMessage);
            break;
          case 404:
            await handle404Error(errorMessage);
            break;
          case 500:
            await handle500Error(errorMessage);
            break;
          case 503:
            await handle503Error(error.response);
            break;
          default:
            await this.alertStore.showValidate(`${errorCode}`);
          // toast.error(`에러: ${error.response.status}`);
        }
      } else if (error.request) {
        if (error.config?.url?.indexOf('/weathers') !== -1) {
          return;
        }
        // 요청은 보냈지만 서버로부터 응답을 받지 못한 경우
        console.error('No response received');
        await this.alertStore.showValidate(
          '응답이 없습니다. 다시 시도해주세요.'
        );
        // toast.error('응답이 없습니다. 다시 시도해주세요.');
      } else {
        // 요청 자체에 문제가 있는 경우
        console.error('Error setting up request');
        await this.alertStore.showValidate('요청에 문제가 있습니다.');
        // toast.error('요청에 문제가 있습니다.');
      }
    };

    const handleRequest = (
      config: InternalAxiosRequestConfig
    ): InternalAxiosRequestConfig => {
      const token = this.commonStore.getSessionStorage('token');
      const membershipId =
        this.userStore.grant === 'WEB_USER'
          ? this.commonStore.getSessionStorage('membershipId')
          : this.userStore.grant === 'WEB_ADMIN'
            ? 'AdminYJ'
            : null;
      if (token) {
        config.headers = (config.headers as AxiosRequestHeaders) || {};
        config.headers.Authorization = `${membershipId} ${token}`;
      }
      this.commonStore.setIsLoading(true);
      return config;
    };
    const handleRequestError = (error: AxiosError): Promise<AxiosError> => {
      this.commonStore.setIsLoading(false);
      if (import.meta.env.VITE_APP_NODE_ENV === 'production') {
        Sentry.withScope((scope) => {
          if (error.config) {
            const { method, url } = error.config;

            scope.setFingerprint([method as string, url as string]);
            scope.setTags({
              method,
              url,
            });

            scope.setContext('axios_request_error', {
              message: error.message,
              url,
              method,
              data: error.response?.data,
              status: error.response?.status,
            });
          }
          Sentry.captureException(error);
        });
      }

      return Promise.reject(error);
    };
    const handleResponse = (response: AxiosResponse): AxiosResponse => {
      this.settingStore.setPreferences(response.headers);
      this.commonStore.setIsLoading(false);
      return response.data;
    };
    const handleResponseError = async (
      error: AxiosError<{ data: string; status: string }>
    ): Promise<AxiosError> => {
      this.commonStore.setIsLoading(false);
      if (import.meta.env.VITE_APP_NODE_ENV === 'production') {
        Sentry.withScope((scope) => {
          if (error.config) {
            const { method, url, params } = error.config;

            scope.setFingerprint([method as string, url as string]);
            scope.setTags({
              url,
              method,
              status: error.response?.status ?? null,
            });

            scope.setContext('axios_response_error', {
              message: error.message,
              url,
              method,
              data: error.response?.data,
              params,
              response: error.response
                ? {
                    data: error.response.data,
                    status: error.response.status,
                  }
                : null,
            });
          }
          Sentry.captureException(error);
        });
      }

      await handleErrorResponse(error);
      return Promise.reject(error);
    };

    this.axiosInstance.interceptors.request.use(
      handleRequest,
      handleRequestError
    );
    this.axiosInstance.interceptors.response.use(
      handleResponse,
      handleResponseError
    );
  }

  /**
   * interface는 클래스의 공개된 api를 정의하는데 사용되며
   * 클래스 내부에서 사용하는 protected, private 메서드는 interface에 정의하지 않는다.
   */
  replacedUrl = (
    urlOrigin: string,
    pathParams: Record<string, string | number>
  ): string =>
    Object.entries(pathParams).reduce(
      (accUrl, [key, value]) => accUrl.replace(`:${key}`, `${value}`),
      urlOrigin
    );

  async get<T>(
    url: string,
    params?: RequestParams,
    config?: HttpRequestConfig
  ): Promise<HttpResponse<T>> {
    const { data, status, statusText, headers } =
      await this.axiosInstance.get<T>(url, {
        params,
        ...config,
      });
    return {
      data,
      status,
      statusText,
      headers,
    };
  }

  async post<T>(
    url: string,
    body?: RequestBody,
    config?: HttpRequestConfig
  ): Promise<HttpResponse<T>> {
    const { data, status, statusText, headers } =
      await this.axiosInstance.post<T>(url, body, config);
    return {
      data,
      status,
      statusText,
      headers,
    };
  }

  async put<T>(
    url: string,
    body?: RequestBody,
    config?: HttpRequestConfig
  ): Promise<HttpResponse<T>> {
    const { data, status, statusText, headers } =
      await this.axiosInstance.put<T>(url, body, config);
    return {
      data,
      status,
      statusText,
      headers,
    };
  }

  async patch<T>(
    url: string,
    body?: RequestBody,
    config?: HttpRequestConfig
  ): Promise<HttpResponse<T>> {
    const { data, status, statusText, headers } =
      await this.axiosInstance.patch<T>(url, body, config);
    return {
      data,
      status,
      statusText,
      headers,
    };
  }

  async delete<T>(
    url: string,
    // body?: RequestBody,
    config?: HttpRequestConfig
  ): Promise<HttpResponse<T>> {
    const { data, status, statusText, headers } =
      await this.axiosInstance.delete<T>(url, config);
    return {
      data,
      status,
      statusText,
      headers,
    };
  }
}

// private async _request<T>(
//   method: 'get' | 'post' | 'put' | 'delete',
//   url: string,
//   data?: RequestBody | RequestParams,
//   config?: HttpRequestConfig
// ): Promise<T> {
//   const response = await this.axiosInstance[method]<T>(
//     url,
//     method === 'get' ? { params: data } : data,
//     config
//   );
//   return response.data;
// }
//
// async get<T>(url: string, params?: RequestParams): Promise<T> {
//   return this._request<T>('get', url, params);
// }
//
// async post<T>(url: string, body?: RequestBody): Promise<T> {
//   return this._request<T>('post', url, body);
// }
//
// async put<T>(url: string, body?: RequestBody): Promise<T> {
//   return this._request<T>('put', url, body);
// }
//
// async delete<T>(url: string): Promise<T> {
//   return this._request<T>('delete', url);
// }

// private async _request<T>(
//   method: 'get' | 'post' | 'put' | 'delete',
//   url: string,
//   data?: RequestBody | RequestParams,
//   config?: HttpRequestConfig
// ): Promise<HttpResponse<T>> {
//   try {
//     // GET 또는 DELETE 요청의 경우 params를 config에 병합
//     if (method === 'get' || method === 'delete') {
//       config = { ...config, params: data };
//       data = undefined;
//     }
//
//     const response = await this.axiosInstance[method]<T>(url, data, config);
//
//     return {
//       data: response.data,
//       status: response.status,
//       statusText: response.statusText,
//       headers: response.headers,
//     };
//   } catch (error) {
//     // 에러 처리
//     throw error;
//   }
// }
