import { ROUTE_NAME } from '@enums';
const reservation = {
  name: ROUTE_NAME.InternetReservation,
  path: '/reservation',
  redirect: '/reservation/reservation',
  children: [
    {
      name: ROUTE_NAME.Reservation,
      path: 'reservation',
      component: () => import('@views/reservation/Reservation.vue'),
      meta: { requiresAuth: true },
    },
    {
      name: ROUTE_NAME.ReservationStatus,
      path: 'reservation-status',
      component: () => import('@views/reservation/ReservationStatus.vue'),
      meta: { requiresAuth: true },
    },
    {
      name: ROUTE_NAME.ReserveModifyView,
      path: 'reserve-modify',
      component: () => import('@views/reservation/ReservationModifyView.vue'),
      meta: { requiresAuth: true },
    },
  ],
};

export default reservation;
