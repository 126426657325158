import { AuthAdapter } from '@adapters';

export class SignOutUseCase {
  constructor(private readonly authAdapter: AuthAdapter) {
    this.authAdapter = authAdapter;
  }

  public async execute(): Promise<boolean> {
    return await this.authAdapter.signOut();
  }
}
