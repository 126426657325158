import { BannerAdapter } from '@adapters';
import { FetchBannerListResponse } from '@dto';

export class FetchBannerListUsecase {
  constructor(private readonly bannerAdapter: BannerAdapter) {
    this.bannerAdapter = bannerAdapter;
  }
  public async execute(): Promise<FetchBannerListResponse> {
    return await this.bannerAdapter.fetchBannerList();
  }
}
