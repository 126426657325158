export const UserApiEndpoint = {
  fetchUserInfo: '/users', // 회원정보조회
  fetchUserDetail: '/users/info', // 회원 이름, 전화번호 조회
  updateRealtimeReservationStatus: '',
  cancelReservation: '',
  signUp: '/users',
  updateUserInfo: '/users', // 회원정보수정
  withdrawal: '/users', // 회원탈퇴
  checkUserIdDuplicate: '/users/check-id', // id 중복체크
  requestPhoneCertification: '/hp/verify-number', //휴대폰 인증번호발송
  confirmPhoneCertification: '/hp/verify-number/confirm', //휴대폰 인증번호 확인
  memberInfoModifyPhoneCertification: '/users/identification-phone', //휴대폰 인증번호발송(회원정보 변경에서 사용)
  memberInfoModifyPhoneConfirm: '/users/verify-number-phone', //휴대폰 인증번호 확인(회원정보 변경에서 사용)
};
