<template>
  <header
    :class="{
      active: !isMain || isScroll,
      hover: isHover,
    }"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div class="top-bar">
      <router-link
        to="/"
        class="logo"
        @click="
          () => {
            if ($route.name === ROUTE_NAME.Main) {
              alertStore.bodyScroll(false);
            }
          }
        "
      >
        <img
          :src="`/logo-${!isMain || isHover || isScroll ? 'color' : 'white'}.svg`"
          alt="메인로고"
        />
      </router-link>
      <ul class="auth-menu">
        <li v-if="!commonStore.isSignIn">
          <router-link :to="{ name: ROUTE_NAME.Signin }">로그인</router-link>
        </li>
        <li v-if="commonStore.isSignIn" @click="handleSignout">로그아웃</li>
        <li class="signup">
          <router-link
            v-if="!commonStore.isSignIn"
            :to="{ name: ROUTE_NAME.SignupAgree }"
            >회원가입</router-link
          >
          <router-link
            v-if="commonStore.isSignIn"
            :to="{ name: ROUTE_NAME.UserInfoModify }"
            >마이페이지</router-link
          >
        </li>
      </ul>
    </div>
    <ul class="gnb">
      <li
        v-for="(gnb, index) in gnbList2"
        :key="index"
        :class="{ active: activeGnb === gnb.name }"
      >
        {{ gnb.title }}
      </li>
    </ul>
    <ul class="lnb" :class="isHover ? 'active-lab-bar' : 'close-lab-bar'">
      <li v-for="(item, index) in lnbList2" :key="index">
        <ul>
          <li v-for="lnb in item" :key="lnb.name">
            <router-link
              :to="{ name: lnb.name }"
              :class="{ active: activeLnb === lnb.name }"
              >{{ lnb.title }}</router-link
            >
          </li>
        </ul>
      </li>
    </ul>
  </header>
</template>
<script setup lang="ts">
import { ROUTE_NAME } from '@enums';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useRouter, RouteLocationNormalized } from 'vue-router';
import { useCommonStore, useUserStore } from '@stores';
import { AuthAdapter, AxiosAdapter } from '@adapters';
import { SignOutUseCase } from '@useCases';
import { useAlertStore } from '@stores';
interface LnbItem {
  title: string;
  name: string;
  isAdmin?: boolean;
}
interface GnbItem {
  title: string;
  name: string;
  children: LnbItem[];
}
const alertStore = useAlertStore();
const axiosAdapter = new AxiosAdapter();
const authAdapter = new AuthAdapter(axiosAdapter);
const commonStore = useCommonStore();
const { currentRoute, push } = useRouter();
const isMain = ref<boolean>(false);
const lnbList2 = ref<LnbItem[][]>([]);
const lnbList = [
  [
    {
      title: '인사말',
      name: ROUTE_NAME.Greeting,
    },
    {
      title: '컨트리클럽소개',
      name: ROUTE_NAME.CountryClubIntroduction,
    },
    {
      title: '오시는길',
      name: ROUTE_NAME.Location,
    },
  ],
  [
    {
      title: '코스소개',
      name: ROUTE_NAME.CourseIntroduction,
    },
    {
      title: '어등코스',
      name: ROUTE_NAME.EdCourse,
    },
    {
      title: '송정코스',
      name: ROUTE_NAME.SjCourse,
    },
    {
      title: '하남코스',
      name: ROUTE_NAME.HnCourse,
    },
  ],
  [
    {
      title: '이용안내',
      name: ROUTE_NAME.UsageInformation,
    },
    {
      title: '요금안내',
      name: ROUTE_NAME.FeeInformation,
    },
    {
      title: '예약안내',
      name: ROUTE_NAME.ReservationInformation,
    },
    {
      title: '이용약관',
      name: ROUTE_NAME.AgreeTerms,
    },
  ],
  [
    {
      title: '인터넷예약',
      name: ROUTE_NAME.Reservation,
    },
    {
      title: '예약확인 및 취소',
      name: ROUTE_NAME.ReservationStatus,
    },
  ],
  [
    {
      title: '클럽하우스',
      name: ROUTE_NAME.ClubHouse,
    },
    {
      title: '레스토랑',
      name: ROUTE_NAME.Restaurant,
    },
    {
      title: '락카룸',
      name: ROUTE_NAME.LockerRoom,
    },
  ],
  [
    {
      title: '공지사항',
      name: ROUTE_NAME.Notice,
    },
    {
      title: '배너관리',
      name: ROUTE_NAME.Banner,
      isAdmin: true,
    },
    {
      title: '자료실',
      name: ROUTE_NAME.Archive,
    },
    {
      title: '포토갤러리',
      name: ROUTE_NAME.PhotoGallery,
    },
  ],
];
const gnbList = [
  {
    title: '클럽소개',
    name: ROUTE_NAME.ClubIntroduction,
  },
  {
    title: '코스안내',
    name: ROUTE_NAME.CourseInformation,
  },
  {
    title: '이용안내',
    name: ROUTE_NAME.UsageInformation,
  },
  {
    title: '인터넷예약',
    name: ROUTE_NAME.InternetReservation,
  },
  {
    title: '부대시설',
    name: ROUTE_NAME.Facilities,
  },
  {
    title: '커뮤니티',
    name: ROUTE_NAME.Community,
  },
];
const gnbList2 = ref<GnbItem[]>();
const activeGnb = ref<string>('');
const activeLnb = ref<string>('');
const isHover = ref<boolean>(false);
const isScroll = ref<boolean>(false);
const userStore = useUserStore();

// const handleRoute = (item: { title: string; name: string }) => {
//   // activeLnb.value = -1;
//   // alertStore.bodyScroll(false);
//   // isMobileMenuClicked.value = false;
//   // currentRoute.value.name !== item.name && push({ name: item.name });
// };

const handleScroll = () => {
  scrollY > 200 ? (isScroll.value = true) : (isScroll.value = false);
};

const handleMouseEnter = () => {
  isHover.value = true;
};

const handleMouseLeave = () => {
  isHover.value = false;
};

const handleSignout = async () => {
  const signOutUseCase = new SignOutUseCase(authAdapter);
  await signOutUseCase.execute();
  await push({ name: ROUTE_NAME.Main });
};

watch(currentRoute, (value: RouteLocationNormalized) => {
  alertStore.bodyScroll(false);
  isMain.value = false;
  // activeLnb.value = value.name ? value.name : '';
  // value.name && (activeLnb.value = value.name as string);
  if (value.name) {
    // console.log(value);
    const { matched } = value;
    const [segment] = matched;
    // const {match} = (activeGnb.value = value as string);
    console.log(segment.name);
    activeGnb.value = segment.name as string;
    activeLnb.value = value.name as string;
  }

  value.name === ROUTE_NAME.Main
    ? (isMain.value = true)
    : (isMain.value = false);
});

watch(
  () => userStore.grant,
  (userGrant) => {
    lnbList2.value = lnbList.map((group) =>
      group.filter((item) => !item.isAdmin || userGrant === 'WEB_ADMIN')
    );
    gnbList2.value = gnbList.map((gnb, index) => ({
      ...gnb,
      children: lnbList2.value[index],
    }));
  },
  {
    immediate: true,
  }
);

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style scoped lang="scss">
header {
  @apply w-full fixed z-50 he-[160];
  transition: all 0.3s ease;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2) 30%,
    rgba(0, 0, 0, 0)
  );
  .active-lab-bar {
    animation: showLnb 0.3s ease;
  }
  .close-lab-bar {
    animation: hideLnb 0.3s ease;
  }
  .top-bar {
    @apply flex justify-between items-center px-16 py-28 fixed w-full;
    @apply desktop:justify-end;
    //transition: all 0.3s ease;
    .logo {
      @apply wi-[155] he-[24] absolute left-1/2 transform -translate-x-1/2 z-40;
      aspect-ratio: 155 / 24;
      img {
        @apply w-full h-full;
      }
    }
    .auth-menu {
      @apply flex text-white fs-[14] items-center cursor-pointer;
      @apply desktop:gap-10 desktop:justify-end;
      transition: all 0.3s ease;
      li {
        a {
          @apply h-full block;
        }
      }
      .signup {
        @apply hidden;
        @apply desktop:block;
      }
    }
  }
  .gnb {
    @apply flex flex-wrap mx-auto justify-center items-end gap-x-[50px] text-white h-full pb-30;
    transition: all 0.3s ease;
    li {
      @apply cursor-pointer fs-[20] wi-[150] text-center relative;
      &.active {
        &:after {
          @apply absolute -bottom-30 left-0 wi-[150] he-[3] bg-orange-100;
          content: '';
        }
      }
    }
  }
  .lnb {
    @apply hidden;
    //transition: all 0.3s ease;
  }
  &.active {
    @apply bg-white;
    background-image: none;
    .top-bar {
      .auth-menu {
        @apply text-black;
      }
    }
    .gnb {
      @apply text-black;
      box-shadow: 0 0.5px 10px 0 rgba(0, 0, 0, 0.15);
    }
  }
  &.hover {
    @apply bg-white;
    .auth-menu {
      @apply text-black;
    }
    .gnb {
      @apply text-black shadow-none;
      li {
        &:after {
          @apply absolute -bottom-30 left-0 wi-[150] he-[0] bg-orange-100;
          content: '';
          transition: all 0.3s ease;
        }
        &:hover {
          @apply relative;
          &:after {
            @apply he-[3];
            content: '';
          }
        }
      }
    }
    .lnb {
      @apply w-full flex items-start justify-center absolute pt-20 pb-60 bg-white text-black text-center gap-x-[50px] border-t border-gray-400;
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.15);
      li {
        @apply wi-[150] mb-20;
        a {
          transition: all 0.3s ease;
          &.active {
            @apply text-orange-100;
          }
          &:hover {
            @apply text-orange-100;
          }
        }
      }
    }
  }
}
@keyframes showLnb {
  from {
    opacity: 0;
    transform: translateY(-150px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hideLnb {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-150px);
  }
}
</style>
